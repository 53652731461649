<template>
    <el-main>
        <el-form ref="form" :model="proxyInfo" label-width="120px" :rules="rules" class="demo-ruleForm">
            <el-form-item label="代理名称：" prop="name">
                <el-input v-model="proxyInfo.name" placeholder="请输入代理名称"></el-input>
            </el-form-item>
            <el-form-item label="所属区域：" prop="active_area">
                <el-cascader v-model="proxyInfo.active_area" :props="{ value: 'id', label: 'name', children: 'city_info' }"
                    placeholder="请选择或搜索" clearable filterable :options="areaList"></el-cascader>
            </el-form-item>
            <el-form-item label="联系人：" prop="contacts_name">
                <el-input v-model="proxyInfo.contacts_name" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="contacts_phone">
                <el-input v-model="proxyInfo.contacts_phone" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="代理价格：" prop="price">
                <el-input v-model="proxyInfo.price" placeholder="请输入代理价格"></el-input>
            </el-form-item>
            <el-form-item label="代理状态：">
                <el-switch v-model="proxyInfo.status" :active-value="1" :inactive-value="0"> </el-switch>
            </el-form-item>
            <el-form-item label="用户openid：" prop="open_id">
                <el-input v-model="proxyInfo.open_id" disabled></el-input><el-button type="primary"
                    @click="chooseUser">选择</el-button>
            </el-form-item>
        </el-form>
        <Preservation @preservation="preservation()"></Preservation>
        <el-dialog title="选择用户" :visible.sync="chooseUserFlag" width="800px">
            <el-form class="el-form-search" label-width="100px">
                <el-form-item label="用户名：">
                    <el-input size="small" v-model="searchUserName" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label=" " label-width="30px">
                    <el-button type="primary" size="small" @click="searchFn()">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="userList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column label="用户信息" align="center">
                    <template v-slot="{ row }">
                        <UserInfo :name="row.nickname" :avatar="row.avatar"></UserInfo>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="right">
                    <template v-slot="{ row }">
                        <el-button type="text" size="small" @click="sureChooseUser(row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Paging :total="userListTotal" :page="userPage" :pageNum="userRows" @updatePageNum="updateData"></Paging>
        </el-dialog>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation'
import UserInfo from '@/components/userInfo'
import Paging from '@/components/paging'
import _ from 'lodash'
export default {
    components: {
        Preservation,
        Paging,
        UserInfo
    },
    data () {
        const checkArea = (rule, value, callback) => {
            if (!this.proxyInfo.active_area || this.proxyInfo.active_area.length != 2) {
                callback('请选择城市')
            } else {
                callback()
            }
        }
        return {
            updateId: null,
            proxyInfo: {
                name: null,
                active_area: null,
                city_id: null,
                contacts_name: null,
                contacts_phone: null,
                price: null,
                status: 1,
                open_id: null,
                user_id: null
            },
            areaList: [],
            cityList: [],
            chooseUserFlag: false,
            searchUserName: null,
            userList: [],
            userPage: 1,
            userRows: 10,
            userListTotal: 0,
            rules: {
                name: [{ required: true, message: '请输入代理名称', trigger: 'blur' }],
                contacts_name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                active_area: [
                    {
                        required: true,
                        type: 'array',
                        validator: checkArea,
                        trigger: 'change'
                    }
                ],
                contacts_phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
                price: [{ required: true, message: '请输入代理价格', trigger: 'blur' }],
                open_id: [{ required: true, message: '请选择用户', trigger: 'change' }]
            }
        }
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.userRows = val
            } else {
                this.userPage = val
            }
            this.getUserList()
        },
        getCityList () {
            let path = !this.updateId
                ? this.$api.push.regionalShareholders.notSelectList
                : this.$api.push.regionalShareholders.notEditSelectList
            let obj = {}
            if (this.updateId) {
                obj.id = this.updateId
            }
            this.$axios.post(path, obj).then((res) => {
                if (res.code == 0) {
                    this.areaList = res.result.list
                }
            })
        },
        chooseUser () {
            this.getUserList()
            this.chooseUserFlag = true
        },
        searchFn () {
            this.userPage = 1
            this.getUserList()
        },
        getUserList () {
            let obj = {
                page: this.userPage,
                rows: this.userRows
            }
            if (this.searchUserName) {
                obj.name = this.searchUserName
            }
            this.$axios.post(this.$api.push.regionalShareholders.getUserList, obj).then((res) => {
                if (res.code == 0) {
                    this.userList = res.result.user_list
                    this.userListTotal = res.result.total_number
                }
            })
        },
        sureChooseUser (row) {
            this.proxyInfo.open_id = row.mini_openid
            this.proxyInfo.user_id = row.id
            this.chooseUserFlag = false
            this.searchUserName = null
        },
        preservation () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (!this.updateId) {
                        let obj = _.cloneDeep(this.proxyInfo)
                        obj.city_id = obj.active_area[1]
                        delete obj.active_area
                        this.$axios
                            .post(this.$api.push.regionalShareholders.ShareholderAdd, obj)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success('添加成功')
                                    this.$router.push('/extension/shareholderDividends/list')
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                    } else {
                        let obj = {
                            id: this.updateId,
                            name: this.proxyInfo.name,
                            city_id: this.proxyInfo.active_area[1],
                            contacts_name: this.proxyInfo.contacts_name,
                            contacts_phone: this.proxyInfo.contacts_phone,
                            price: this.proxyInfo.price,
                            status: this.proxyInfo.status,
                            open_id: this.proxyInfo.open_id,
                            user_id: this.proxyInfo.user_id
                        }
                        this.$axios.post(this.$api.push.regionalShareholders.edit, obj).then((res) => {
                            if (res.code == 0) {
                                this.$message.success('编辑成功')
                                this.$router.push('/extension/shareholderDividends/list')
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    }
                }
            })
        },
        getUpdateInfo () {
            this.$axios
                .post(this.$api.push.regionalShareholders.info, {
                    id: this.updateId
                })
                .then((res) => {
                    if (res.code == 0) {
                        let { info } = res.result
                        this.proxyInfo = info
                        this.$set(this.proxyInfo, 'active_area', [info.province_id, info.city_id])
                    }
                })
        }
    },
    created () {
        if (this.$route.query.id) {
            this.updateId = this.$route.query.id
            this.getUpdateInfo()
        }
        this.getCityList()
    }
}
</script>

<style lang="less" scoped>
.el-main {
    height: 100%;
    background-color: #fff;

    .el-form {

        .el-input,
        .el-cascader {
            width: 400px;
        }
    }
}
</style>
